import React, { useState } from 'react';
import AboutUs from './About/Aboutus';
import './App.css';
import BookingOpen from './BookingOpen/BookingOpen';
import ImageCarousel from './Carousel/Carousel';
import Contact from './Contact/Contact';
import Dialog from './Dialog/Dialog';
import Floaction from './Floacting/Flocating';
import FloorPlan from './FloorPlan/FloorPlan';
import Footer from './Footer/Footer';
import Gallery from './Gallery/Gallery';
import Header from './Header/Header';
import Masterpiece from './Masterpiece/Masterpiece';
import ProjectConfiguration from './ProjectConfiguration/ProjectConfiguration';
import VirtualTour from './VirtualTour/VirtualTour';

// const images = [
//   { url: indoorImage1, alt: 'Image 1', caption: 'Caption for Image 1' },

//   { url: indoorImage2, alt: 'Image 2', caption: 'Caption for Image 2' },
//   { url: indoorImage3, alt: 'Image 2', caption: 'Caption for Image 2' },
//   // Add more image objects as needed
// ];

function App() {


  const [showDialog, setShowDialog] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowDialog(true);
  //   }, 5000); // Open dialog after 5 seconds

  //   return () => clearTimeout(timer);
  // }, []);


  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="App">
    <div id="header">
    <Header />
    </div>
      <div id="carousel"><ImageCarousel /></div>
      <div id='bookingopen'><BookingOpen onOpenDialog={openDialog}/></div>
      <div id="masterpiece"><Masterpiece /></div>
      <Floaction onOpenDialog={openDialog}/>
      <div id="configuration"><ProjectConfiguration onOpenDialog={openDialog}/> </div>
      <div id="gallery">  <Gallery/></div>
      <div id="virtual-tour"><VirtualTour /></div>
      <div id="floor-plan"><FloorPlan onOpenDialog={openDialog} /></div>
      <div id="about"><AboutUs /></div>
      <div id="contact"><Contact /></div>
      {showDialog && <Dialog onClose={closeDialog}/>}
      <Footer />
     
    </div>
  );
}

export default App;
