// Floaction.js
import React from 'react';
import { FaEnvelope, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import '../Floacting/Floacting.css'; // Import the CSS file for styling

const Floaction = ({onOpenDialog}) => {
  return (
    <div className="floaction">
      <a href="tel:+919920992558" className="floaction-button call">
        <FaPhoneAlt /><h1 >Call</h1>
      </a>
      <a href='#' onClick={onOpenDialog} className="floaction-button email">
        <FaEnvelope /> <h1 >Enquire</h1>
      </a>
      <a href="https://wa.me/9920992558" className="floaction-button whatsapp">
        <FaWhatsapp className='whatsappp'/><h1>Whatsapp</h1>
      </a>
    </div>
  );
};

export default Floaction;
